import axios from 'axios'
import apiBaseUrl from "./apiConfig";

export const getIndicators = () => {
    return axios.get(`${apiBaseUrl}/indicators`)
}

export const getDrivers = () => {
    return axios.get(`${apiBaseUrl}/drivers`)
}

export const getDrivingData = (driverId) => {
    return axios.get(`${apiBaseUrl}/driving-data/${driverId}`)
}

export const getAlerts = (driverId) => {
    return axios.get(`${apiBaseUrl}/alerts/${driverId}`)
}

export const getDriverIndicators = (driverId) => {
    return axios.get(`${apiBaseUrl}/indicators/${driverId}`)
}