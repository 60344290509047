import React, { useEffect, useState } from "react";
import './main-page.scss'
import MainLayout from "../../layouts/main";
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent';
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import Divider from "../../components/Divider"
import GaugeComponent from 'react-gauge-component'
import Select from "@mui/material/Select"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { getIndicators, getDrivers, getDrivingData, getAlerts, getDriverIndicators } from '../../utils/apiRoutes'

export default function MainPage() {
    const isMobile = window.innerWidth < 800
    const [indicators, setIndicators] = useState({
        pontuacao: {
            value: 0,
            minValue: 0,
            maxValue: 100,
            limit: 10,
            average: 0
        },
        pontuacaoPonderada: {
            value: 0,
            minValue: 0,
            maxValue: 100,
            limit: 10,
            average: 0
        },
        tempoEmDirecao: {
            value: 0,
            minValue: 0,
            maxValue: 100,
            limit: 10,
            average: 0
        },
        frenagemBrusca: {
            value: 0,
            minValue: 0,
            maxValue: 100,
            limit: 10,
            average: 0
        },
        aceleracaoBrusca: {
            value: 0,
            minValue: 0,
            maxValue: 100,
            limit: 10,
            average: 0
        },
        aceleracaoLateral: {
            value: 0,
            minValue: 0,
            maxValue: 100,
            limit: 10,
            average: 0
        },
        deslocamentoEmNeutro: {
            value: 0,
            minValue: 0,
            maxValue: 100,
            limit: 10,
            average: 0
        },
        rpmElevado: {
            value: 0,
            minValue: 0,
            maxValue: 3,
            limit: 1,
            average: 0
        },
        ociosidade: {
            value: 0,
            minValue: 0,
            maxValue: 100,
            limit: 10,
            average: 0
        },
    })

    const [drivers, setDrivers] = useState([])
    const [driverId, setDriverId] = useState()
    const [driverDrivingData, setDriverDrivingData] = useState()
    const [driverAlerts, setDriverAlerts] = useState([])
    const [driverIndicators, setDriverIndicators] = useState(null)

    const updateIndicatorValue = (indicatorName, newValue) => {
        setIndicators(prevIndicators => ({
          ...prevIndicators,
          [indicatorName]: {
            ...prevIndicators[indicatorName],
            value: newValue
          }
        }));
    };

    const updateIndicatorBase = (indicatorName, property, newValue) => {
        setIndicators(prevIndicators => ({
            ...prevIndicators,
            [indicatorName]: {
                ...prevIndicators[indicatorName],
                [property]: newValue
            }
        }));
    };

    const indicatorsLimit = (maxVal, minVal) => {
        if (minVal < 0)
            maxVal += minVal
        
        return maxVal / 3;
    }

    function filterByIndicator(arrayObj, indicador) {
        return arrayObj.filter(item => item.indicator === indicador);
    }

    const populateIndicators = () => {

        getIndicators().then(({data}) => {
            let limit = 0
            let indicator;

            indicator = filterByIndicator(data, 'Pontuacao')[0]
            updateIndicatorBase('pontuacao', 'maxValue', indicator.max_val)
            updateIndicatorBase('pontuacao', 'minValue', indicator.min_val < 0 ? 0 : indicator.min_val)
            updateIndicatorBase('pontuacao', 'limit', indicatorsLimit(indicator.max_val, indicator.min_val))
            updateIndicatorBase('pontuacao', 'average', indicator.average < 0 ? 0 : indicator.average)

            indicator = filterByIndicator(data, 'Pontuacao_ponderada')[0]
            updateIndicatorBase('pontuacaoPonderada', 'maxValue', indicator.max_val)
            updateIndicatorBase('pontuacaoPonderada', 'minValue', indicator.min_val < 0 ? 0 : indicator.min_val)
            updateIndicatorBase('pontuacaoPonderada', 'limit', indicatorsLimit(indicator.max_val, indicator.min_val))
            updateIndicatorBase('pontuacaoPonderada', 'average', indicator.average < 0 ? 0 : indicator.average)

            indicator = filterByIndicator(data, 'Tempo_em_direcao')[0]
            updateIndicatorBase('tempoEmDirecao', 'maxValue', indicator.max_val)
            updateIndicatorBase('tempoEmDirecao', 'minValue', indicator.min_val < 0 ? 0 : indicator.min_val)
            updateIndicatorBase('tempoEmDirecao', 'limit', indicatorsLimit(indicator.max_val, indicator.min_val))
            updateIndicatorBase('tempoEmDirecao', 'average', indicator.average < 0 ? 0 : indicator.average)

            indicator = filterByIndicator(data, 'Frenagem_brusca')[0]
            updateIndicatorBase('frenagemBrusca', 'maxValue', indicator.max_val)
            updateIndicatorBase('frenagemBrusca', 'minValue', indicator.min_val)
            updateIndicatorBase('frenagemBrusca', 'limit', indicatorsLimit(indicator.max_val, indicator.min_val))
            updateIndicatorBase('frenagemBrusca', 'average', indicator.average < 0 ? 0 : indicator.average)

            indicator = filterByIndicator(data, 'Aceleracao_brusca')[0]
            updateIndicatorBase('aceleracaoBrusca', 'maxValue', indicator.max_val)
            updateIndicatorBase('aceleracaoBrusca', 'minValue', indicator.min_val)
            updateIndicatorBase('aceleracaoBrusca', 'limit', indicatorsLimit(indicator.max_val, indicator.min_val))
            updateIndicatorBase('aceleracaoBrusca', 'average', indicator.average < 0 ? 0 : indicator.average)

            indicator = filterByIndicator(data, 'Aceleracao_lateral_brusca')[0]
            updateIndicatorBase('aceleracaoLateral', 'maxValue', indicator.max_val)
            updateIndicatorBase('aceleracaoLateral', 'minValue', indicator.min_val)
            updateIndicatorBase('aceleracaoLateral', 'limit', indicatorsLimit(indicator.max_val, indicator.min_val))
            updateIndicatorBase('aceleracaoLateral', 'average', indicator.average < 0 ? 0 : indicator.average)

            indicator = filterByIndicator(data, 'Deslocamento_em_neutro')[0]
            updateIndicatorBase('deslocamentoEmNeutro', 'maxValue', indicator.max_val)
            updateIndicatorBase('deslocamentoEmNeutro', 'minValue', indicator.min_val)
            updateIndicatorBase('deslocamentoEmNeutro', 'limit', indicatorsLimit(indicator.max_val, indicator.min_val))
            updateIndicatorBase('deslocamentoEmNeutro', 'average', indicator.average < 0 ? 0 : indicator.average)

            indicator = filterByIndicator(data, 'Rpm_elevado')[0]
            updateIndicatorBase('rpmElevado', 'maxValue', indicator.max_val <= 3 ? 3 : indicator.max_val)
            updateIndicatorBase('rpmElevado', 'minValue', indicator.max_val <= 3 ? 0 : indicator.min_val)
            updateIndicatorBase('rpmElevado', 'limit', indicator.max_val <= 3 ? 1 : indicatorsLimit(indicator.max_val, indicator.min_val))
            updateIndicatorBase('rpmElevado', 'average', indicator.average < 0 ? 0 : indicator.average)

            indicator = filterByIndicator(data, 'Ociosidade')[0]
            updateIndicatorBase('ociosidade', 'maxValue', indicator.max_val)
            updateIndicatorBase('ociosidade', 'minValue', indicator.min_val)
            updateIndicatorBase('ociosidade', 'limit', indicatorsLimit(indicator.max_val, indicator.min_val))
            updateIndicatorBase('ociosidade', 'average', indicator.average < 0 ? 0 : indicator.average)

        })

    }

    const populateDrivers = () => {
        getDrivers().then(({data}) => {
            setDrivers(data);
        })
    }

    useEffect(() => {
        populateIndicators();
        populateDrivers();
    }, [])

    const onDriverChange = (event) => {
        setDriverId(event.target.value);
    }

    useEffect(() => {
        if(driverId){
            populateDrivingData();
            populateAlerts();
            populateDriverIndicators();
        }
    }, [driverId])

    useEffect(() => {
        if(driverIndicators) {
            updateIndicatorValue('pontuacao', !driverIndicators['pontuacao'] || driverIndicators['pontuacao'] < 0 ? 0 : driverIndicators['pontuacao'])
            updateIndicatorValue('pontuacaoPonderada', !driverIndicators['pontuacao_ponderada'] || driverIndicators['pontuacao_ponderada'] < 0 ? 0 : driverIndicators['pontuacao_ponderada'])
            updateIndicatorValue('tempoEmDirecao', !driverIndicators['tempo_em_direcao'] || driverIndicators['tempo_em_direcao'] < 0 ? 0 : driverIndicators['tempo_em_direcao'])
            updateIndicatorValue('frenagemBrusca', !driverIndicators['frenagem_brusca'] || driverIndicators['frenagem_brusca'] < 0 ? 0 : driverIndicators['frenagem_brusca'])
            updateIndicatorValue('aceleracaoBrusca', !driverIndicators['aceleracao_brusca'] || driverIndicators['aceleracao_brusca'] < 0 ? 0 : driverIndicators['aceleracao_brusca'])
            updateIndicatorValue('aceleracaoLateral', !driverIndicators['aceleracao_lateral_brusca'] || driverIndicators['aceleracao_lateral_brusca'] < 0 ? 0 : driverIndicators['aceleracao_lateral_brusca'])
            updateIndicatorValue('deslocamentoEmNeutro', !driverIndicators['deslocamento_em_neutro'] || driverIndicators['deslocamento_em_neutro'] < 0 ? 0 : driverIndicators['deslocamento_em_neutro'])
            updateIndicatorValue('rpmElevado', !driverIndicators['rpm_elevado'] || driverIndicators['rpm_elevado'] < 0 ? 0 : driverIndicators['rpm_elevado'])
            updateIndicatorValue('ociosidade', !driverIndicators['ociosidade'] || driverIndicators['ociosidade'] < 0 ? 0 : driverIndicators['ociosidade'])
        }
    }, [driverIndicators])

    const populateDrivingData = () => {
        getDrivingData(driverId).then(({data}) => {
            setDriverDrivingData(data.length > 0 ? data[0] : [])
        })
    }

    const populateAlerts = () => {
        getAlerts(driverId).then(({data}) => {
            setDriverAlerts(data.length > 0 ? data : [])
        })
    }

    const populateDriverIndicators = () => {
        getDriverIndicators(driverId).then(({data}) => {
            setDriverIndicators(data.length > 0 ? data[0] : [])
        })
    }

    return (
        <MainLayout>
            <Grid container className="results-section" direction="column" gap={6}>
                <Card className="card">
                    <CardContent className="content">
                        <Grid container className="title" alignItems="center" justifyContent="space-between">
                            <Typography variant="h5" component="h3"><strong>Informações do Motorista</strong></Typography>
                            <FormControl className="" variant="standard" style={{ width: isMobile ? '100%' : '20%' }}>
                                <InputLabel id="driver-select-label">Motorista</InputLabel>
                                <Select
                                    labelId="driver-select-label"
                                    id="driver-select"
                                    value={driverId}
                                    name="driver"
                                    onChange={onDriverChange}
                                >
                                    {drivers.map((driver, index) => (
                                        <MenuItem key={index} value={driver}>
                                            {driver}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            
                        </Grid>
                        <Box my={2} />
                        <Divider />
                        <Box py={1} />
                        <Grid container gap={ isMobile ? 0 : 2 }>
                            <Typography variant="h7"><strong>Dados de condução</strong></Typography>
                            <Grid container justifyContent="space-between" gap={ isMobile ? 1 : 6 }>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="placa-frota"
                                        label="Placa | Frota"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        defaultValue={''}
                                        value={driverDrivingData && driverDrivingData['placa'] ? driverDrivingData['placa'] : ''}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="tempo-ligado-h"
                                        label="Tempo ligado (H)"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['tempo_ligado_h'] ? driverDrivingData['tempo_ligado_h'].toFixed(3) : ''}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="km-percorrido"
                                        label="Km percorrido"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['km'] ? driverDrivingData['km'].toFixed(3) : ''}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="litros"
                                        label="Litros"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['litros'] ? driverDrivingData['litros'].toFixed(2) : ''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" gap={ isMobile ? 1 : 6 }>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="tempo-faixa-verde-h"
                                        label="Tempo na faixa verde (H)"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['tempo_faixa_verde_h'] ? driverDrivingData['tempo_faixa_verde_h'].toFixed(3) : ''}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="tempo-ligado-parado-h"
                                        label="Tempo ligado parado (H)"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['tempo_ligado_parado'] ? driverDrivingData['tempo_ligado_parado'].toFixed(3) : ''}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="km-l"
                                        label="Km / L"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['km_l'] ? driverDrivingData['km_l'].toFixed(3) : ''}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="km-l-veiculo"
                                        label="Km / L Veículo"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['km_l_veiculo'] ? driverDrivingData['km_l_veiculo'].toFixed(3) : ''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" gap={ isMobile ? 1 : 6 }>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="desempenho-motorista"
                                        label="Desempenho do motorista (%)"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['desempenho_motorista'] ? driverDrivingData['desempenho_motorista'].toFixed(3) : ''}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="km-l-ppm"
                                        label="Km / L PPM"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['km_l_ppm'] ? driverDrivingData['km_l_ppm'] : ''}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="desempenho-ppm"
                                        label="Desempenho PPM (%)"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['desempenho_ppm'] ? driverDrivingData['desempenho_ppm'].toFixed(3) : ''}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="coeficiente"
                                        label="Coeficiente (%)"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['coeficiente'] ? driverDrivingData['coeficiente'].toFixed(3) : ''}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" gap={ isMobile ? 1 : 6 }>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="tempo-ligado-parado-%"
                                        label="Tempo ligado parado (%)"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['tempo_ligado_parado_%'] ? driverDrivingData['tempo_ligado_parado_%'].toFixed(3) : ''}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="tempo-faixa-verde-%"
                                        label="Tempo na faixa verde (%)"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['tempo_faixa_verde_%'] ? driverDrivingData['tempo_faixa_verde_%'].toFixed(3) : ''}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="custo-ocioso"
                                        label="Custo ocioso (R$)"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['custo_ocioso']? driverDrivingData['custo_ocioso'].toFixed(3) : ''}
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: 1, flexShrink: 1, minWidth: '200px' }}>
                                    <TextField
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        id="custo-ocioso-excedido"
                                        label="Custo ocioso excedido (R$)"
                                        variant="standard"
                                        style={{ width: '100%' }}
                                        value={driverDrivingData && driverDrivingData['custo_ocioso_excedido'] ? driverDrivingData['custo_ocioso_excedido'].toFixed(3) : ''}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box my={4} />
                        <Divider />
                        <Box my={1} />
                        {driverAlerts.length > 0 && 
                            <Grid container gap={2}>
                                <Typography variant="h7"><strong>Alertas</strong></Typography>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                        <TableRow>
                                            <TableCell>Tipo</TableCell>
                                            <TableCell align="left">Placa</TableCell>
                                            <TableCell align="left">Frota</TableCell>
                                            <TableCell align="left">Início do alerta</TableCell>
                                            <TableCell align="left">Duração (min)</TableCell>
                                            <TableCell align="left">Endereço</TableCell>
                                            <TableCell align="left">RPM Max</TableCell>
                                        </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {driverAlerts.map((row, key) => (
                                            <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="left">{row.tipo_alerta}</TableCell>
                                            <TableCell align="left">{row.placa}</TableCell>
                                            <TableCell align="left">{row.frota}</TableCell>
                                            <TableCell align="left">{row.inicio_alerta}</TableCell>
                                            <TableCell align="left">{row.duracao_min.toFixed(2)}</TableCell>
                                            <TableCell align="left">{row.endereco}</TableCell>
                                            <TableCell align="left">{row.rpm_max}</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        }
                        
                    </CardContent>
                </Card>
                <Card className="card">
                    <CardContent className="content">
                        <Grid container className="title" alignItems="center" justifyContent="space-between">
                            <Typography variant="h5" component="h3"><strong>Indicadores</strong></Typography>
                        </Grid>
                        <Box my={2} />
                        <Divider />
                        <Box py={2} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box component="section" style={{ border: '1px solid grey' }}>
                                    <Typography variant="h5" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Pontuação
                                    </Typography>
                                    <Typography variant="h6" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Média: {indicators.pontuacao.average.toFixed(2)}
                                    </Typography>
                                    <GaugeComponent 
                                        type="semicircle"
                                        arc={{
                                            colorArray: ['#FF2121', '#00FF15'],
                                            padding: 0.05,
                                            subArcs: [
                                                { limit: indicators.pontuacao.limit },
                                                { limit: indicators.pontuacao.limit * 2 },
                                                { limit: indicators.pontuacao.limit * 3 },
                                            ]
                                        }}
                                        pointer={{type: "arrow", elastic: true}}
                                        value={indicators.pontuacao.value}
                                        minValue={indicators.pontuacao.minValue}
                                        maxValue={indicators.pontuacao.maxValue}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box component="section" style={{ border: '1px solid grey' }}>
                                    <Typography variant="h5" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Pontuação ponderada
                                    </Typography>
                                    <Typography variant="h6" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Média: {indicators.pontuacaoPonderada.average.toFixed(2)}
                                    </Typography>
                                    <GaugeComponent 
                                        type="semicircle"
                                        arc={{
                                            colorArray: ['#FF2121', '#00FF15'],
                                            padding: 0.05,
                                            subArcs: [
                                                { limit: indicators.pontuacaoPonderada.limit },
                                                { limit: indicators.pontuacaoPonderada.limit * 2 },
                                                { limit: indicators.pontuacaoPonderada.limit * 3 },
                                            ]
                                        }}
                                        pointer={{type: "arrow", elastic: true}}
                                        value={indicators.pontuacaoPonderada.value}
                                        minValue={indicators.pontuacaoPonderada.minValue}
                                        maxValue={indicators.pontuacaoPonderada.maxValue}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box component="section" style={{ border: '1px solid grey' }}>
                                    <Typography variant="h5" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Tempo em direção
                                    </Typography>
                                    <Typography variant="h6" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Média: {indicators.tempoEmDirecao.average.toFixed(2)}
                                    </Typography>
                                    <GaugeComponent 
                                        type="semicircle"
                                        arc={{
                                            colorArray: ['#FF2121', '#00FF15'],
                                            padding: 0.05,
                                            subArcs: [
                                                { limit: indicators.tempoEmDirecao.limit },
                                                { limit: indicators.tempoEmDirecao.limit * 2 },
                                                { limit: indicators.tempoEmDirecao.limit * 3 },
                                            ]
                                        }}
                                        pointer={{type: "arrow", elastic: true}}
                                        value={indicators.tempoEmDirecao.value}
                                        minValue={indicators.tempoEmDirecao.minValue}
                                        maxValue={indicators.tempoEmDirecao.maxValue}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box component="section" style={{ border: '1px solid grey' }}>
                                    <Typography variant="h5" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Frenagem brusca
                                    </Typography>
                                    <Typography variant="h6" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Média: {indicators.frenagemBrusca.average.toFixed(2)}
                                    </Typography>
                                    <GaugeComponent 
                                        type="semicircle"
                                        arc={{
                                            colorArray: ['#00FF15', '#FF2121'],
                                            padding: 0.02,
                                            subArcs: [
                                                { limit: indicators.frenagemBrusca.limit },
                                                { limit: indicators.frenagemBrusca.limit * 2 },
                                                { limit: indicators.frenagemBrusca.limit * 3 },
                                            ]
                                        }}
                                        pointer={{type: "arrow", elastic: true}}
                                        value={indicators.frenagemBrusca.value}
                                        minValue={indicators.frenagemBrusca.minValue}
                                        maxValue={indicators.frenagemBrusca.maxValue}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box component="section" style={{ border: '1px solid grey' }}>
                                    <Typography variant="h5" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Aceleração brusca
                                    </Typography>
                                    <Typography variant="h6" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Média: {indicators.aceleracaoBrusca.average.toFixed(2)}
                                    </Typography>
                                    <GaugeComponent 
                                        type="semicircle"
                                        arc={{
                                            colorArray: ['#00FF15', '#FF2121'],
                                            padding: 0.02,
                                            subArcs: [
                                                { limit: indicators.aceleracaoBrusca.limit },
                                                { limit: indicators.aceleracaoBrusca.limit * 2 },
                                                { limit: indicators.aceleracaoBrusca.limit * 3 },
                                            ]
                                        }}
                                        pointer={{type: "arrow", elastic: true}}
                                        value={indicators.aceleracaoBrusca.value}
                                        minValue={indicators.aceleracaoBrusca.minValue}
                                        maxValue={indicators.aceleracaoBrusca.maxValue}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box component="section" style={{ border: '1px solid grey' }}>
                                    <Typography variant="h5" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Aceleração lateral
                                    </Typography>
                                    <Typography variant="h6" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Média: {indicators.aceleracaoLateral.average.toFixed(2)}
                                    </Typography>
                                    <GaugeComponent 
                                        type="semicircle"
                                        arc={{
                                            colorArray: ['#00FF15', '#FF2121'],
                                            padding: 0.02,
                                            subArcs: [
                                                { limit: indicators.aceleracaoLateral.limit },
                                                { limit: indicators.aceleracaoLateral.limit * 2 },
                                                { limit: indicators.aceleracaoLateral.limit * 3 },
                                            ]
                                        }}
                                        pointer={{type: "arrow", elastic: true}}
                                        value={indicators.aceleracaoLateral.value}
                                        minValue={indicators.aceleracaoLateral.minValue}
                                        maxValue={indicators.aceleracaoLateral.maxValue}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box component="section" style={{ border: '1px solid grey' }}>
                                    <Typography variant="h5" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Deslocamento em neutro
                                    </Typography>
                                    <Typography variant="h6" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Média: {indicators.deslocamentoEmNeutro.average.toFixed(2)}
                                    </Typography>
                                    <GaugeComponent 
                                        type="semicircle"
                                        arc={{
                                            colorArray: ['#00FF15', '#FF2121'],
                                            padding: 0.02,
                                            subArcs: [
                                                { limit: indicators.deslocamentoEmNeutro.limit },
                                                { limit: indicators.deslocamentoEmNeutro.limit * 2 },
                                                { limit: indicators.deslocamentoEmNeutro.limit * 3 },
                                            ]
                                        }}
                                        pointer={{type: "arrow", elastic: true}}
                                        value={indicators.deslocamentoEmNeutro.value}
                                        minValue={indicators.deslocamentoEmNeutro.minValue}
                                        maxValue={indicators.deslocamentoEmNeutro.maxValue}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box component="section" style={{ border: '1px solid grey' }}>
                                    <Typography variant="h5" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        RPM elevado
                                    </Typography>
                                    <Typography variant="h6" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Média: {indicators.rpmElevado.average.toFixed(2)}
                                    </Typography>
                                    <GaugeComponent 
                                        type="semicircle"
                                        arc={{
                                            colorArray: ['#00FF15', '#FF2121'],
                                            padding: 0.02,
                                            subArcs: [
                                                { limit: indicators.rpmElevado.limit },
                                                { limit: indicators.rpmElevado.limit * 2 },
                                                { limit: indicators.rpmElevado.limit * 3 },
                                            ]
                                        }}
                                        pointer={{type: "arrow", elastic: true}}
                                        value={indicators.rpmElevado.value}
                                        minValue={indicators.rpmElevado.minValue}
                                        maxValue={indicators.rpmElevado.maxValue}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box component="section" style={{ border: '1px solid grey' }}>
                                    <Typography variant="h5" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Ociosidade
                                    </Typography>
                                    <Typography variant="h6" component="div" style={{ textAlign: 'center', marginTop: '5%' }}>
                                        Média: {indicators.ociosidade.average.toFixed(2)}
                                    </Typography>
                                    <GaugeComponent 
                                        type="semicircle"
                                        arc={{
                                            colorArray: ['#00FF15', '#FF2121'],
                                            padding: 0.02,
                                            subArcs: [
                                                { limit: indicators.ociosidade.limit },
                                                { limit: indicators.ociosidade.limit * 2 },
                                                { limit: indicators.ociosidade.limit * 3 },
                                            ]
                                        }}
                                        pointer={{type: "arrow", elastic: true}}
                                        value={indicators.ociosidade.value}
                                        minValue={indicators.ociosidade.minValue}
                                        maxValue={indicators.ociosidade.maxValue}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

            </Grid>
        </MainLayout >
    )
}